<script>
import AppCard from "@/components/atoms/AppCard.vue";
import AppChart from "@/components/atoms/AppChart.vue";
import AppMenu from "@/components/atoms/AppMenu.vue";
import ReportCardSubtitle from "./ReportCardSubtitle.vue";
import paymentMethods from "@/constants/payment-methods";
import ReportDetailList from "@/components/organisms/ReportDetailList.vue";

export default {
  components: {
    AppCard,
    AppMenu,
    AppChart,
    ReportCardSubtitle,
    ReportDetailList,
  },
  name: "ReportRevenueCard",
  data() {
    return {
      localData: [],
    };
  },
  props: {
    title: String,
    dataList: Array,
    total: Number,
    cardKey: String,
    currentSort: String,
    currentFilter: String,
    members: Array,
    billingList: Array,
  },
  created() {
    this.prepareChartData();
    this.populateInfo();
  },
  beforeUpdate() {
    this.prepareChartData();

    this.populateInfo();
  },
  methods: {
    changeOrder(payload) {
      this.$emit("changeOrder", {
        newOrder: payload,
        cardKey: this.cardKey,
      });
    },
    changeFilter(payload) {
      this.$emit("changeFilter", {
        newOrder: payload,
        cardKey: this.cardKey,
      });
    },
    prepareChartData() {
      this.localData = JSON.parse(JSON.stringify(this.dataList));
    },
    populateInfo() {
      this.localData.forEach((data) => {
        paymentMethods.find((method) => {
          if (method.value === data.method) {
            data.method = method.text;
            data.color = method.color;
          }
        });
      });
    },
    clickMenu(payload) {
      this.$emit("clickMenu", payload);
    },
  },
  computed: {
    percentages() {
      let percentages = [];

      this.localData.forEach((data) => {
        // console.log(this.total);
        // console.log(data.value);
        if (this.total != 0) {
          percentages.push((data.value * 100) / this.total);
        } else {
          percentages.push(0);
        }
      });
      // console.log(percentages);
      return percentages;
    },
    backgroundColors() {
      let colors = [];
      this.localData.forEach((data) => {
        colors.push(data.color);
      });
      return colors;
    },
  },
};
</script>

<template>
  <app-card style="height: calc((var(--vh) * 100) - 222px)">
    <div class="report-card-container">
      <div class="report-card-graph">
        <div class="report-subtitle">
          <h3>{{ title }}</h3>
          <report-card-subtitle
            :dataList="localData"
            :cardKey="cardKey"
            style="padding-left: 4px"
          />
        </div>

        <div class="report-chart">
          <app-chart
            :backgroundColor="total != 0 ? backgroundColors : ['#000000']"
            :data="total != 0 ? percentages : [-1]"
          />
        </div>
      </div>
      <div class="report-card-filters">
        <app-menu
          title="Ordenar"
          buttonType="new-default"
          color="#FFFFFF"
          icon="mdi-swap-vertical"
          textColor="#94ACCF"
          :current="currentSort"
          :items="[
            {
              text: 'Nome',
              value: 'name',
            },
            {
              text: 'Valor',
              value: 'price',
            },
            {
              text: 'Data',
              value: 'date',
            },
          ]"
          @click="changeOrder"
          customStyle="width: calc(100% - 1px) ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid rgba(148, 172, 207, 0.4);"
        />
        <app-menu
          buttonType="new-default"
          title="Filtrar"
          color="#FFFFFF"
          icon="mdi-filter-variant"
          textColor="#94ACCF"
          :current="currentFilter"
          :items="[
            {
              text: 'Mensalidades',
              value: 'monthly',
            },
            {
              text: 'Outros',
              value: 'others',
            },
            {
              text: 'Todos',
              value: 'all',
            },
          ]"
          @click="changeFilter"
          customStyle="width: calc(100% - 1px) ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid rgba(148, 172, 207, 0.4);"
        />
      </div>
      <div class="report-card-details" style="overflow-y: scroll">
        <report-detail-list
          :members="members"
          :billingList="billingList"
          :type="cardKey"
          @clickMenu="clickMenu"
          :currentSort="currentSort"
          :currentFilter="currentFilter"
        />
      </div>
    </div>
  </app-card>
</template>

<style lang="scss" scoped>
.report-card-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
}

.report-card-filters {
  display: flex;
  gap: 2px;
  padding: 0 42px;

  .v-btn {
    width: calc(50% - 1px) !important;
  }
}

.report-card-graph {
  display: flex;
  width: 100%;
  padding: 8px;
  height: 222px;

  h3 {
    padding: 0;
    margin: 0;
    text-align: left;
    height: 20%;
  }

  .report-subtitle {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 40%;
    box-sizing: border-box;
  }

  .report-chart {
    padding: 1em 0;
    width: 60%;
  }
}
</style>
