<script>
import MemberCard from "../molecules/MemberCard.vue";
import AppList from "@/components/atoms/AppList.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    MemberCard,
    AppList,
  },
  name: "MemberList",
  data() {
    return {};
  },
  props: {
    members: {
      type: Array,
      default: () => {
        [];
      },
    },
    billingList: {
      type: Array,
      default: () => {
        [];
      },
    },
    currentSorting: {
      type: String,
      default: "name",
    },
    currentFilter: {
      type: String,
      default: "active",
    },
  },
  methods: {
    clickBilling(event) {
      this.$emit("clickBilling", event);
    },
    clickProfile(event) {
      this.$emit("clickProfile", event);
    }, // Local Methods
    sortByName(list) {
      return list ? list.sort((a, b) => a.name.localeCompare(b.name)) : [];
    },
    sortByBilling(list) {
      return list
        ? list.sort(
            (a, b) =>
              moment(this.getMemberBilling(a.id)?.reference).diff(
                this.getMemberBilling(b.id)?.reference
              ) ||
              moment(this.getMemberBilling(a.id)?.date).diff(
                this.getMemberBilling(b.id)?.date
              )
          )
        : [];
    },
    sortByGrad(list) {
      return list ? list.sort((a, b) => b.graduation - a.graduation) : [];
    },
    getMemberBilling(memberId) {
      if (this.billingList != undefined) {
        let memberBilling = this.billingList.filter(
          (billing) => billing.memberId === memberId
        );
        memberBilling.sort((a, b) => moment(b.reference) - moment(a.reference));
        return memberBilling[0];
      }
    },
    applyFilter(list, parameter) {
      return list
        ? list.filter(
            (a) => a.academies[this.currentAcademy.id].isActive == parameter
          )
        : [];
    },
  },
  computed: {
    ...mapGetters({
      currentAcademy: "academy/currentAcademy",
    }),
    currentMembers: function () {
      switch (this.currentSorting) {
        case "name":
          return this.sortByName(this.filteredMembers);
        case "billing":
          return this.sortByBilling(this.filteredMembers);
        case "grad":
          return this.sortByGrad(this.filteredMembers);
        default:
          return [];
      }
    },
    filteredMembers: function () {
      switch (this.currentFilter) {
        case "active":
          return this.applyFilter(this.members, true);
        case "inactive":
          return this.applyFilter(this.members, false);
        case "all":
          return this.members;
        default:
          return [];
      }
    },
  },
};
</script>

<template>
  <section id="member-list-container" v-if="members != undefined">
    <app-list
      v-if="members.length > 0"
      id="member-list"
      customStyle="padding-bottom: 96px !important;  padding-top: 0;display: flex; flex-direction: column"
    >
      <member-card
        v-for="member in currentMembers"
        v-bind:key="member.id"
        :id="member.id"
        :name="member.name"
        :billing="getMemberBilling(member.id)"
        :graduation="member.graduation"
        @clickBilling="clickBilling"
        @clickProfile="clickProfile"
      ></member-card>
    </app-list>
  </section>
</template>

<style lang="scss">
#member-list {
  gap: 4px 16px !important;
}
section {
  overflow: visible;
  width: 100%;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 !important;
}

.v-btn--fixed.v-btn--bottom {
  bottom: calc(64px + 16px);
}

.toolbar-container {
  padding: 40px 5vw;
}

#member-list-container {
  .v-list-item {
    @media screen and (min-width: 768px) {
      flex: 1 1 400px;
      max-width: max(calc(50% - 16px), 400px);
    }
    @media screen and (min-width: 1200px) {
      flex: 1 1 calc((100% - 32px) / 3);
      max-width: calc((100% - 32px) / 3);
    }
  }
}
</style>
