<script>
import bottomMenu from "@/constants/bottom-menu";

import TheBottomNavigation from "@/components/organisms/TheBottomNavigation.vue";
import TheSidebar from "@/components/organisms/TheSidebar.vue";
import MemberListTemplate from "./MemberListTemplate.vue";
import ExamsTemplate from "./ExamsTemplate.vue";
import ReportTemplate from "./ReportTemplate.vue";

export default {
  components: {
    TheSidebar,
    TheBottomNavigation,
    ReportTemplate,
    MemberListTemplate,
    ExamsTemplate,
  },
  name: "HomeTemplate",
  data() {
    return {
      options: bottomMenu,
      reportsKey: 0,
      width: 0,
    };
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setWidth);
  },
  props: {
    hasAcademy: {
      type: Boolean,
      default: false,
    },
    currentAcademy: Object,
    currentTab: String,
    members: Array,
    billingList: Array,
    hasBilling: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    currentTab() {
      if (this.currentTab == "reports") {
        this.reportsKey += 1;
      }
    },
  },
  methods: {
    clickExpense() {
      this.$emit("clickExpense");
    },
    setWidth() {
      this.width = window.innerWidth;
    },
    search(payload) {
      this.$emit("search", payload);
    },
    fabClick() {
      this.$emit("fabClick");
    },
    changeFilter(payload) {
      this.$emit("changeFilter", payload);
    },
    clickBilling(memberId) {
      this.$emit("clickBilling", memberId);
    },
    billingEdit(event) {
      this.$emit("billingEdit", event);
    },
    billingDelete(event) {
      this.$emit("billingDelete", event);
    },
    expenseEdit(event) {
      this.$emit("expenseEdit", event);
    },
    clickProfile(memberId) {
      this.$emit("clickProfile", memberId);
    },
    clickLogout() {
      this.$emit("clickLogout");
    },
    changeTab(payload) {
      this.$emit("changeTab", payload);
    },
  },
};
</script>

<template>
  <div class="home-container">
    <the-sidebar
      v-if="width >= 1280"
      :width="width"
      :options="options"
      :currentTab="currentTab"
      @change="changeTab"
      @clickLogout="clickLogout"
    />
    <span class="main-content">
      <span v-show="currentTab === 'members'">
        <member-list-template
          v-if="members != undefined"
          :members="members"
          :billingList="billingList"
          :hasAcademy="hasAcademy"
          :currentAcademy="currentAcademy"
          @change="search"
          @fabClick="fabClick"
          @changeFilter="changeFilter"
          @clickProfile="clickProfile"
          @clickLogout="clickLogout"
          @clickBilling="clickBilling"
        />
      </span>

      <span v-show="currentTab === 'reports'">
        <report-template
          v-if="hasBilling"
          :hasBilling="hasBilling"
          :billingList="billingList"
          :key="reportsKey"
          :members="members"
          :hasAcademy="hasAcademy"
          :currentAcademy="currentAcademy"
          @expenseEdit="expenseEdit"
          @billingDelete="billingDelete"
          @billingEdit="billingEdit"
          @memberShow="clickProfile"
          @clickLogout="clickLogout"
          @clickExpense="clickExpense"
        />
      </span>
      <span v-show="currentTab === 'exams'">
        <exams-template
          :currentAcademy="currentAcademy"
          @clickLogout="clickLogout"
        />
      </span>
    </span>
    <the-bottom-navigation
      v-if="width < 1280"
      :options="options"
      :currentTab="currentTab"
      @change="changeTab"
      @clickLogout="clickLogout"
    />
  </div>
</template>

<style lang="scss">
.v-sheet.v-card:not(.v-sheet--outlined) {
  border-radius: 20px;
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);
}

.home-container {
  overflow: visible;
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 5vw;
    gap: 64px;
  }
}

.main-content {
  @media screen and (min-width: 1200px) {
    position: fixed;
    left: calc(5vw + 144px);
  }
  @media screen and (min-width: 1600px) {
    left: calc(5vw + 320px);
  }
}
</style>
