<script>
import AppChartDoughnut from "./AppChartDoughnut.vue";
import AppChartBar from "./AppChartBar.vue";

export default {
  name: "AppChart",
  components: {
    AppChartBar,
    AppChartDoughnut,
  },
  props: {
    backgroundColor: Array,
    data: Array,
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    width: {
      type: Number,
      default: 180,
    },
    chartType: {
      type: String,
      default: "doughnut",
    },
    height: {
      type: Number,
      default: 180,
    },
  },
  data() {
    return {
      show: 0,
    };
  },
  beforeUpdate() {
    this.show += 1;
  },
};
</script>

<template>
  <span>
    <app-chart-doughnut
      v-if="chartType === 'doughnut'"
      :key="show"
      :backgroundColor="backgroundColor"
      :data="data"
      :chartId="chartId"
      :width="width"
      :height="height"
      style="transform: translate(-8px, 8px)"
    />
    <app-chart-bar
      v-if="chartType === 'bar'"
      :key="show"
      :backgroundColor="backgroundColor"
      :data="data"
      chartId="bar-chart"
      :width="width"
      :height="height"
      style="
        padding: 16px 48px;
        max-height: 24vh;
        transform: translate(-16px, 16px);
      "
    />
  </span>
</template>
