<script>
import AppCard from "@/components/atoms/AppCard.vue";
import AppChart from "@/components/atoms/AppChart.vue";
import ReportCardSubtitle from "./ReportCardSubtitle.vue";
import userReportCategory from "@/constants/user-report-category";

export default {
  components: { AppCard, AppChart, ReportCardSubtitle },
  name: "ReportMemberCard",
  data() {
    return {
      localData: [],
    };
  },
  props: {
    title: String,
    dataList: Array,
    total: Number,
    cardKey: String,
    currentSort: String,
    currentFilter: String,
    members: Array,
    billingList: Array,
  },
  created() {
    this.prepareChartData();
    this.populateInfo();
  },
  updated() {
    this.populateInfo();
  },
  methods: {
    /* changeOrder(payload) {
      this.$emit("changeOrder", {
        newOrder: payload.value,
        cardKey: this.cardKey,
      });
    },
    changeFilter(payload) {
      this.$emit("changeFilter", {
        newOrder: payload.value,
        cardKey: this.cardKey,
      });
    }, */
    prepareChartData() {
      this.localData = JSON.parse(JSON.stringify(this.dataList));
    },
    populateInfo() {
      this.localData.forEach((data) => {
        userReportCategory.find((UserCategory) => {
          if (UserCategory.value === data.method) {
            data.method = UserCategory.text;
            data.color = UserCategory.color;
          }
        });
      });
    },
    changeFilter(payload) {
      this.$emit("changeFilter", payload);
    },
    changeOrder(payload) {
      this.$emit("changeOrder", payload);
    },
  },
  computed: {
    percentages() {
      let percentages = [];
      this.localData.forEach((data) => {
        percentages.push((data.value * 100) / this.total);
      });
      return percentages;
    },
    backgroundColors() {
      let colors = [];
      this.localData.forEach((data) => {
        colors.push(data.color);
      });
      return colors;
    },
  },
};
</script>

<template>
  <app-card style="height: calc(100vh - 222px)">
    <div class="report-card-container">
      <div class="report-card-graph">
        <div class="report-subtitle">
          <h3>{{ title }}</h3>
          <report-card-subtitle
            :dataList="localData"
            :cardKey="cardKey"
            style="padding-left: 4px"
          />
        </div>

        <div class="report-chart">
          <app-chart :backgroundColor="backgroundColors" :data="percentages" />
        </div>
      </div>
      <!-- FILTERS HIDDEN 
        <div class="report-card-filters">
        <app-menu
          title="Ordenar"
          buttonType="new-default"
          color="#FFFFFF"
          icon="mdi-swap-vertical"
          textColor="#94ACCF"
          :current="currentSort"
          :items="[
            {
              text: 'Nome',
              value: 'name',
            },
            {
              text: 'Valor',
              value: 'price',
            },
            {
              text: 'Data',
              value: 'date',
            },
          ]"
          @click="changeOrder"
          customStyle="width: calc(100% - 1px) ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid rgba(148, 172, 207, 0.4);"
        />
        <app-menu
          buttonType="new-default"
          title="Filtrar"
          color="#FFFFFF"
          icon="mdi-filter-variant"
          textColor="#94ACCF"
          :current="currentFilter"
          :items="
            cardKey === 'balance'
              ? [
                  {
                    text: 'Despesas',
                    value: 'expense',
                  },
                  {
                    text: 'Entradas',
                    value: 'income',
                  },
                  {
                    text: 'Todos',
                    value: 'all',
                  },
                ]
              : [
                  {
                    text: 'Mensalidades',
                    value: 'monthly',
                  },
                  {
                    text: 'Outros',
                    value: 'others',
                  },
                  {
                    text: 'Todos',
                    value: 'all',
                  },
                ]
          "
          @click="changeFilter"
          customStyle="width: calc(100% - 1px) ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid rgba(148, 172, 207, 0.4);"
        />
      </div> -->
      <div class="report-card-details" style="overflow-y: scroll">
        <!-- what to use this space for ? -->
      </div>
    </div>
  </app-card>
</template>

<style lang="scss" scoped>
.report-card-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
}

.report-card-filters {
  display: flex;
  gap: 2px;
  padding: 0 42px;

  .v-btn {
    width: calc(50% - 1px) !important;
  }
}

.report-card-graph {
  display: flex;
  width: 100%;
  padding: 8px;
  height: 222px;

  h3 {
    padding: 0;
    margin: 0;
    text-align: left;
    height: 20%;
  }

  .report-subtitle {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 40%;
    box-sizing: border-box;
  }

  .report-chart {
    padding: 1em 0;
    width: 60%;
  }
}
</style>
