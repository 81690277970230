const bottomMenu = [
  {
    value: "members",
    icon: "mdi-account-multiple-outline",
    text: "Membros",
  },
  {
    value: "exams",
    icon: "mdi-chevron-double-up",
    text: "Exames",
  },
  {
    value: "reports",
    icon: "mdi-chart-box-outline",
    text: "Relatórios",
  },
];

export default bottomMenu;
