<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "AppChartBar",
  components: {
    Bar,
  },
  props: {
    backgroundColor: Array,
    data: Array,
    chartId: {
      type: String,
      default: "bar-chart",
    },
    width: {
      type: Number,
      default: 120,
    },
    height: {
      type: Number,
      default: 120,
    },
  },
  created() {
    this.chartData.datasets[0].backgroundColor = this.backgroundColor;
    this.chartData.datasets[0].data = this.data;
    this.chartData.labels = this.backgroundColor;
  },
  beforeUpdate() {
    this.chartData.datasets[0].backgroundColor = this.backgroundColor;
    this.chartData.datasets[0].data = this.data;
    this.chartData.labels = this.backgroundColor;
  },
  data() {
    return {
      chartData: {
        /*  labels: ["VueJs", "EmberJs", "ReactJs"], */
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [80, 20],
            barThickness: 32,
            maxBarThickness: 32,
            minBarLength: 2,
            borderRadius: 8,
            borderSkipped: false,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        animation: {
          duration: 0,
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            display: false, // Hide Y
            beginAtZero: true, // Hide X axis labels
            offset: true,
          },
          x: {
            display: false,
            beginAtZero: false, // Hide X axis labels
          },
        },
        events: [],
      },
    };
  },
};
</script>

<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :width="width"
    :height="height"
  />
</template>
