var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list-item',{staticClass:"report-detail-item-container"},[_c('span',{staticClass:"report-member-info"},[_c('div',{staticClass:"report-member-title"},[_c('p',{staticClass:"report-member-name"},[_vm._v(_vm._s(_vm.formatedName))]),_c('p',{staticClass:"report-member-method",style:(_vm.billing.type != 'expense'
            ? 'text-align: right; color: #759A58'
            : 'text-align: right; color: #C04545')},[_vm._v(" "+_vm._s(_vm.billing.type != "expense" ? "Entrada" : "Saída")+" ")])]),_c('div',{staticClass:"report-member-data-container"},[_c('div',{staticClass:"report-member-data"},[(_vm.billing.id != undefined)?_c('p',{staticClass:"report-member-data-date"},[_c('app-icon',{attrs:{"color":"#797979","size":"16","icon":"mdi-calendar-today"}}),_vm._v(" "+_vm._s(_vm.formatedDate)+" ")],1):_c('p'),_c('p',{staticClass:"report-member-data-price",style:(_vm.textStyle(_vm.billing.type, _vm.billing.price))},[_c('app-icon',{style:(_vm.textStyle(_vm.billing.type, _vm.billing.price)),attrs:{"size":"16","icon":"mdi-currency-usd"}}),_vm._v(" "+_vm._s(_vm.billingPrice)+" ")],1)])])]),(_vm.billing.id != undefined)?_c('app-menu',{attrs:{"buttonType":"new-icon","customStyle":"width: 32px ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid #94ACCF;","icon":"mdi-dots-horizontal","items":[
      {
        text: 'Editar',
        value: 'expenseEdit',
      },
      {
        text: 'Excluir',
        value: 'delete',
      } ]},on:{"click":_vm.clickMenu}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }