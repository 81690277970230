<script>
import ReportCardSubtitleItem from "@/components/molecules/ReportCardSubtitleItem.vue";

export default {
  components: { ReportCardSubtitleItem },
  name: "ReportCardSubtitle",
  data() {
    return {};
  },
  props: {
    dataList: Array,
    cardKey: String,
  },
};
</script>

<template>
  <div class="report-subtitle-container">
    <report-card-subtitle-item
      v-for="data in dataList"
      v-bind:key="data.title"
      :title="data.method"
      :value="data.value"
      :color="data.color"
      :cardKey="cardKey"
      :style="data.method === 'Total' ? 'padding-top: 18px' : ''"
    />
  </div>
</template>

<style lang="scss">
.report-subtitle-container {
  box-sizing: border-box;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
