<script>
import MemberOrder from "@/components/molecules/MemberOrder.vue";
import AppInput from "@/components/atoms/AppInput.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import MemberList from "@/components/organisms/MemberList.vue";
import TheToolbar from "@/components/organisms/TheToolbar.vue";
import ModularCard from "@/components/molecules/ModularCard.vue";

export default {
  name: "MemberListTemplate",
  components: {
    TheToolbar,
    AppInput,
    AppButton,
    MemberOrder,
    MemberList,
    ModularCard,
  },
  props: {
    members: Array,
    currentAcademy: Object,
    hasAcademy: {
      type: Boolean,
      default: false,
    },
    billingList: {
      type: Array,
    },
  },
  data() {
    return {
      currentSorting: "name",
      currentFilter: "active",
      searchText: "",
      noMemberCard: {
        0: {
          type: "title",
          title: "Nenhum aluno registrado!",
        },
        1: {
          type: "image",
          image: "no-members",
        },
        2: {
          subtitle: "Clique no botão abaixo para cadastrar seu primeiro aluno.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle: "Somente você tem acesso aos dados dos seus alunos.",
        },
        4: {
          type: "buttons",
        },
      },
    };
  },
  methods: {
    menuClick(payload) {
      switch (payload) {
        case "userProfile":
          this.$router.push("/account");
          break;
        case "payment":
          this.$router.push("/payment");
          break;
        case "settings":
          this.$router.push("/settings");
          break;
        case "logout":
          this.clickLogout();
          break;
        default:
          this.$router.push("/");
          break;
      }
    },
    // Emited events
    clickBilling(event) {
      this.$emit("clickBilling", event);
    },
    clickProfile(event) {
      this.$emit("clickProfile", event);
    },
    fabClick() {
      this.$emit("fabClick");
    },
    emit(payload) {
      this.searchText = payload.value;
    },
    clickLogout() {
      this.$emit("clickLogout");
    },

    changeSorting(payload) {
      this.currentSorting = payload;
    },
    changeFilter(payload) {
      this.currentFilter = payload;
    },
    // Local Methods
    search(list) {
      if (this.searchText.length > 0 && list) {
        return list.filter((user) =>
          user.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        return list;
      }
    },
  },
  computed: {
    filteredMembers: function () {
      return this.search(this.members);
    },
  },
};
</script>

<template>
  <section id="member-list-template" class="section-container">
    <the-toolbar :academy="currentAcademy" @clickIconRight="menuClick" />

    <span v-if="members.length == 0" style="width: 100%">
      <modular-card
        :cardModules="noMemberCard"
        customStyle="min-height: calc((var(--vh, 1vh) * 100) - 192px) !important; justify-content: space-between;"
      >
        <app-button
          name="submit"
          text="Adicionar Membro"
          type="new-default"
          width="100%"
          @click="fabClick"
        />
      </modular-card>
    </span>
    <span
      id="member-list-content"
      v-if="members.length > 0"
      style="width: 100%"
    >
      <span id="member-list-filters">
        <span id="member-list-actions">
          <app-input
            name="search"
            inputType="search"
            icon="mdi-magnify"
            label="Buscar"
            :value="searchText"
            @change="emit"
          />
          <app-button
            name="submit"
            text="Adicionar Membro"
            icon="mdi-account-plus"
            type="new-default"
            width="100%"
            @click="fabClick"
          />
        </span>
        <member-order
          :currentSorting="currentSorting"
          :currentFilter="currentFilter"
          @clickOrder="changeSorting"
          @clickFilter="changeFilter"
        />
      </span>
      <span style="transform: translateY(-8px); width: 100%">
        <member-list
          :members="filteredMembers"
          :billingList="billingList"
          :currentSorting="currentSorting"
          :currentFilter="currentFilter"
          @clickBilling="clickBilling"
          @clickProfile="clickProfile"
        />
      </span>
    </span>
    <!-- <app-button
      v-if="members.length > 0"
      type="fab"
      icon="mdi-account-plus"
      color="accent"
      @fabClick="fabClick"
    /> -->
  </section>
  <!-- add here the 'no member and no academy'  -->
</template>

<style lang="scss">
.theme--light.v-list {
  background-color: transparent;
}
#member-list-template {
  min-height: calc((var(--vh, 1vh) * 100) - 192px);
}
.section-container {
  gap: 28px;
  padding: 16px !important;
  justify-content: flex-start !important;
  width: 100vw;

  @media screen and (min-width: 1200px) {
    padding: 0 !important;
    width: calc(90vw - 128px);
  }

  @media screen and (min-width: 1600px) {
    width: calc(90vw - 320px);
  }
}

#member-list-template {
  padding-bottom: 96px;
}

#member-list-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

#member-list-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  #member-list-actions {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  @media screen and (min-width: 1200px) {
    width: 60%;
    transform: translateY(8px);
    margin-left: auto;
    flex-direction: row-reverse;
  }
}
</style>
