<script>
import userOrder from "@/constants/user-order";
import userFilter from "@/constants/user-filter";

import AppMenu from "@/components/atoms/AppMenu.vue";

export default {
  components: {
    AppMenu,
  },
  name: "MemberOrder",
  props: {
    currentSorting: String,
    currentFilter: String,
  },
  data() {
    return {
      userOrder,
      userFilter,
    };
  },
  methods: {
    clickOrder(event) {
      this.$emit("clickOrder", event);
    },
    clickFilter(event) {
      this.$emit("clickFilter", event);
    },
  },
};
</script>

<template>
  <span style="display: flex; padding: 0px 12px; gap: 4px">
    <app-menu
      title="Ordenar"
      color="primary"
      textColor="white"
      icon="mdi-swap-vertical"
      :items="userOrder"
      :current="currentSorting"
      customStyle="border-radius: 20px 5px 5px 20px;"
      @click="clickOrder"
    />
    <app-menu
      title="Filtrar"
      color="white"
      textColor="#B1B1B1"
      icon="mdi-filter-variant"
      :items="userFilter"
      :current="currentFilter"
      customStyle="border-radius: 5px 20px 20px 5px;"
      @click="clickFilter"
    />
  </span>
  <!-- <app-item-group :value="currentSorting" customStyle="padding: 8px 0;">
    <app-container :fillHeight="false" customClass="user-tabs-container">
      <app-flex-row>
        <app-flex-column
          v-for="order in userOrder"
          :key="order.value"
          cols="4"
          customStyle="padding: 6px;"
        >
          <app-item-group-item>
            <app-card
              id="app-member-filter-tabs"
              :color="currentSorting === order.value ? '#245CAF' : ''"
              :customClass="
                currentSorting === order.value
                  ? 'text-white d-flex align-center'
                  : 'text-gray d-flex align-center'
              "
              customStyle="margin: 0 4px;"
              @click="click(order.value)"
            >
              <app-scroll-transition type="horizontal">
                <div class="flex-grow-1 text-center">
                  <small>{{ order.text }}</small>
                </div>
              </app-scroll-transition>
            </app-card>
          </app-item-group-item>
        </app-flex-column>
      </app-flex-row>
    </app-container>
  </app-item-group> -->
</template>

<style lang="scss">
#app-member-filter-tabs {
  @media screen and (min-width: 1200px) and (max-width: 1499px) {
    height: 36px;

    small {
      font-size: 13px;
    }
  }
  @media screen and (min-width: 1500px) {
    height: 40px;

    small {
      font-size: 14px;
    }
  }
}

.user-tabs-container {
  width: 100% !important;
  margin: 0 auto;
  padding: 0;
  overflow: visible !important;
}

small {
  font-weight: 700;
  font-size: 12px;
}
</style>
