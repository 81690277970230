var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list-item',{staticClass:"report-detail-item-container",attrs:{"customStyle":"min-height: auto !important; padding: 0px; "}},[_c('span',{staticClass:"report-member-info"},[_c('div',{staticClass:"report-member-title"},[_c('p',{staticClass:"report-member-name"},[_vm._v(_vm._s(_vm.formatedName))]),_c('p',{staticClass:"report-member-method",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.billingMethod)+" ")])]),_c('div',{staticClass:"report-member-data-container"},[_c('div',{staticClass:"report-member-data"},[_c('p',{staticClass:"report-member-data-date"},[_c('app-icon',{attrs:{"color":"#797979","size":"16","icon":"mdi-calendar-today"}}),_vm._v(" "+_vm._s(_vm.formatedDate)+" ")],1),_c('p',{staticClass:"report-member-service",staticStyle:{"text-align":"left"}},[_vm._v(" Mensalidade ")]),_c('p',{staticClass:"report-member-data-price",style:(Number(_vm.billing.price) > 0 ? 'color: #759A58' : 'color: #C04545')},[_c('app-icon',{attrs:{"color":Number(_vm.billing.price) > 0 ? '#759A58' : '#C04545',"size":"16","icon":"mdi-currency-usd"}}),_vm._v(" "+_vm._s(_vm.billingPrice)+" ")],1)])])]),(_vm.billing.id != undefined)?_c('app-menu',{attrs:{"buttonType":"new-icon","customStyle":"width: 32px ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid #94ACCF;","icon":"mdi-dots-horizontal","items":[
      {
        text: 'Ver Perfil',
        value: 'profile',
      },
      {
        text: 'Editar',
        value: 'billingEdit',
      },
      {
        text: 'Excluir',
        value: 'delete',
      } ]},on:{"click":_vm.clickMenu}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }