<script>
import { getGraduationList } from "@/helpers/get-graduation-list";
import AppGraduationBadge from "../atoms/AppGraduationBadge.vue";
import AppAvatar from "../atoms/AppAvatar.vue";
import AppIcon from "../atoms/AppIcon.vue";
import AppButton from "../atoms/AppButton.vue";
import AppListItem from "../atoms/AppListItem.vue";
import format from "@/helpers/format";

export default {
  components: {
    AppGraduationBadge,
    AppAvatar,
    AppButton,
    AppIcon,
    AppListItem,
  },
  name: "MemberCard",
  data() {
    return {
      currentGraduation: {
        graduation: undefined,
        color: undefined,
        textColor: undefined,
      },
      graduationList: [],
    };
  },
  props: {
    id: String,
    name: {
      type: String,
      default: "",
    },
    billing: {
      type: Object,
    },
    graduation: {
      default: undefined,
    },
  },
  methods: {
    emit(event) {
      this.$emit(event, this.id);
    },
    setGraduationList() {
      this.graduationList = getGraduationList(this.genre);
    },
    getGraduationObject() {
      if (this.graduation != undefined && typeof this.graduation === "number") {
        this.currentGraduation = this.graduationList.find(
          (item) => item.value === this.graduation
        );
      }
    },
  },
  computed: {
    formatedDate: function () {
      return format.date(this.billing.date);
    },
    genre: function () {
      return this.$store.state.academy.currentAcademy.genre;
    },
    formatedReference: function () {
      return format.reference(this.billing.reference);
    },
    formatedName: function () {
      let formatedName = format.name(this.name);
      if (formatedName.length > 36) {
        return formatedName.substring(0, 35) + "...";
      } else {
        return formatedName;
      }
    },
  },
  mounted() {
    this.setGraduationList();
    this.getGraduationObject();
  },
};
</script>

<template>
  <app-list-item>
    <span class="member-card-container">
      <span class="member-card">
        <span class="member-avatar">
          <app-avatar
            image=""
            customHeight="80px"
            customWidth="96px"
            customStyle="border-radius: 20px 0 0 0; margin: 0;"
          />
          <app-graduation-badge
            v-if="Object.keys(currentGraduation).length > 0"
            :graduation="currentGraduation.graduation"
            :color="currentGraduation.color"
            :text-color="currentGraduation.textColor"
            customStyle="border-radius: 0 0 0 20px;"
          />
        </span>
        <span class="member-info">
          <div class="member-name">
            <p>{{ formatedName }}</p>
          </div>
          <div v-if="billing != undefined" class="member-date">
            <p>
              <small
                ><app-icon
                  color="#797979"
                  size="22"
                  icon="mdi-calendar-today"
                />
                {{ formatedDate }}
              </small>
            </p>
            <p>
              <small>
                <app-icon
                  color="#797979"
                  size="22"
                  icon="mdi-calendar-check-outline"
                />
                {{ formatedReference }}</small
              >
            </p>
          </div>
        </span>
      </span>
      <span class="member-card-buttons">
        <app-button
          type="icon"
          @click="emit('clickProfile')"
          size="50px"
          borderRadius="5px 20px 5px 5px"
          color="#FFFFFF"
          icon="mdi-account-circle-outline"
        />
        <app-button
          type="icon"
          @click="emit('clickBilling')"
          size="50px"
          borderRadius="5px 5px 20px 5px"
          color="#FFFFFF"
          icon="mdi-currency-usd"
          iconColor="green"
        />
      </span>
    </span>
  </app-list-item>
</template>

<style lang="scss" scoped>
P {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: 14px;

  small {
    font-weight: 500;
    font-size: 12px;
    color: #797979;
  }
}

.member-card-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  gap: 4px;
  flex-direction: row;
  justify-content: space-around;
}

.member-card {
  border-radius: 20px 5px 5px 20px;
  box-sizing: border-box;
  background-color: white;
  width: 100%;

  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);
}

.member-name {
  text-align: left;
}

.member-card-buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.member-info {
  padding: 10px 8px 10px 8px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  justify-content: space-between;
  @media screen and (min-width: 1200px) {
    padding: 10px 24px 10px 8px;
  }
}

.member-date {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 0px !important;
  }

  /* @media screen and (min-width: 1200px) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    } */
}

.member-avatar {
  display: flex;
  flex-direction: column;
}
</style>
