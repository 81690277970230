<script>
import ModularCard from "@/components/molecules/ModularCard.vue";
import TheToolbar from "@/components/organisms/TheToolbar.vue";

export default {
  name: "ExamsTemplate",
  components: { ModularCard, TheToolbar },
  data() {
    return {
      underConstructionCard: {
        0: {
          type: "title",
          title: "Em Construção!",
        },
        1: {
          type: "image",
          image: "under-construction",
        },
        2: {
          subtitle: "Está área do DojoSei ainda está em desenvolvimento.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle:
            "Em breve você terá acesso a fazer Exames de Faixa pelo DojoSei.",
        },
      },
    };
  },
  props: {
    currentAcademy: Object,
  },
  methods: {
    menuClick(payload) {
      switch (payload) {
        case "userProfile":
          this.$router.push("/account");
          break;
        case "payment":
          this.$router.push("/payment");
          break;
        case "settings":
          this.$router.push("/settings");
          break;
        case "logout":
          this.clickLogout();
          break;
        default:
          this.$router.push("/");
          break;
      }
    },
    emit(payload) {
      this.$emit("click", payload);
    },
    clickLogout() {
      this.$emit("clickLogout");
    },
  },
};
</script>

<template>
  <section id="exams-template" class="section-container">
    <the-toolbar :academy="currentAcademy" @clickIconRight="menuClick" />

    <span style="width: 100%; padding-bottom: 72px">
      <modular-card
        :cardModules="underConstructionCard"
        customStyle="min-height: calc((var(--vh, 1vh) * 100) - 192px) !important; justify-content: flex-start; gap: 32px;"
      />
    </span>
  </section>
</template>

<style lang="scss" scoped>
#exams-template {
  min-height: calc((var(--vh, 1vh) * 100) - 56px) !important;
  max-height: calc((var(--vh, 1vh) * 100) - 56px) !important;
}
</style>
