<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "AppCarousel",
  components: { VueSlickCarousel },
  data() {
    return {
      carouselController: 0,
      settings: {
        adaptiveHeight: true,
        arrows: false,
        centerMode: true,
        centerPadding: "0px",
        dots: false,
        focusOnSelect: true,
        infinite: false,
      },
    };
  },
};
</script>

<template>
  <div class="carousel-container">
    <VueSlickCarousel v-bind="settings">
      <slot />
    </VueSlickCarousel>
  </div>
</template>

<style>
.carousel-container {
  overflow: visible;
  /* margin-right: -0.5em; */
  /* margin: 0 2vw; */
}
.slick-list {
  overflow: visible !important;
  margin: 0 -1vw !important;
}
.slick-slide > div {
  margin: 0 1vw;
}
</style>
