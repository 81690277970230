<script>
import ReportDetailItemIncome from "./ReportDetailItemIncome.vue";
import ReportDetailItemBalance from "./ReportDetailItemBalance.vue";

export default {
  components: {
    ReportDetailItemBalance,
    ReportDetailItemIncome,
  },
  name: "ReportDetailItem",
  props: {
    id: String,
    type: String,
    name: {
      type: String,
      default: "",
    },
    billing: {
      type: Object,
    },
  },

  methods: {
    clickMenu(payload) {
      this.$emit("clickMenu", payload);
    },
  },
};
</script>

<template>
  <span class="report-detail-container" style="width: 100%">
    <report-detail-item-income
      v-if="type === 'revenue'"
      :id="id"
      :name="name"
      :type="type"
      :billing="billing"
      @clickMenu="clickMenu"
    />
    <report-detail-item-balance
      v-if="type === 'balance'"
      :id="id"
      :name="name"
      :type="type"
      :billing="billing"
      @clickMenu="clickMenu"
    />
  </span>
</template>

<style lang="scss">
.report-detail-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
</style>
