<script>
import bottomMenu from "@/constants/bottom-menu";

export default {
  name: "TheBottomNavigation",
  data: () => ({
    options: bottomMenu,
    placeholder: true,
    activeTab: "members",
  }),
  props: {
    currentTab: String,
  },
  methods: {
    change(event) {
      this.$emit("change", event);
    },
  },
};
</script>

<template>
  <v-bottom-navigation
    :value="currentTab"
    color="#607ECC"
    :fixed="placeholder"
    :mandatory="placeholder"
    shift
  >
    <v-btn
      :value="option.value"
      v-for="option in options"
      v-bind:key="option.value"
      style="overflow: visible"
      @click="change(option.value)"
    >
      <span style="font-size: 12px; font-weight: 700">{{ option.text }}</span>

      <v-icon size="32" class="icon-effect">{{ option.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<style lang="scss">
.v-bottom-navigation {
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid rgba(255, 255, 255, 0.25);

  .v-btn--active {
    .icon-effect,
    .v-icon {
      text-shadow: 0px 0px 20px #0c8aff !important;
    }
  }

  .v-btn:before {
    background-color: #ffffff;
  }

  .v-btn__content {
    overflow: visible;

    background-color: #ffffff;
  }
}
</style>
