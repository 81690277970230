<script>
import AppButton from "@/components/atoms/AppButton.vue";
import AppInput from "@/components/atoms/AppInput.vue";
import ReportCardList from "@/components/organisms/ReportCardList.vue";
import ModularCard from "@/components/molecules/ModularCard.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import TheToolbar from "@/components/organisms/TheToolbar.vue";
import moment from "moment";
import format from "@/helpers/format";

export default {
  name: "ReportTemplate",
  components: {
    TheToolbar,
    AppInput,
    ReportCardList,
    AppButton,
    AppDialog,
    ModularCard,
  },
  data() {
    return {
      reference: moment().format("L"),
      dialog: false,
      billingData: {},
      totalRevenue: 0,
      totalMembers: 0,
      cards: {
        balance: {
          title: "Entrada / Saída",
          chartType: "bar",
          data: [],
        },
        revenue: {
          title: "Formas de Pagamento",
          data: [],
        },
        members: {
          title: "Alunos",
          data: [],
        },
      },
      noBillingCard: {
        0: {
          type: "title",
          title: "Relatório Financeiro!",
        },
        1: {
          type: "image",
          image: "no-members",
        },
        2: {
          subtitle:
            "Aqui você terá acesso ao relatório financeiro da sua Academia.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle:
            "Registre as mensalidades dos alunos, as despesas da academia e entenda seu fluxo financeiro!",
        },
        4: {
          type: "subtitle",
          subtitle: "Clique no botão abaixo adicionar uma nova despesa.",
        },
        5: {
          type: "buttons",
        },
      },
    };
  },
  created() {
    this.reference = moment().format("YYYY-MM");
    let keys = Object.keys(this.cards);
    keys.forEach((key) => {
      this.getCardTotal(this.cards[key]);
    });
    this.getCardsInfo();
  },
  updated() {
    this.getCardsInfo();
  },
  props: {
    members: Array,
    currentAcademy: Object,
    hasAcademy: {
      type: Boolean,
      default: false,
    },
    billingList: Array,
    hasBilling: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    billingEdit(event) {
      this.$emit("billingEdit", event);
    },
    expenseEdit(event) {
      this.$emit("expenseEdit", event);
    },
    deleteConfirm() {
      this.dialog = false;
      this.$emit("billingDelete", this.billingData);
    },
    deleteCancel() {
      this.dialog = false;
    },
    expenseDelete(payload) {
      this.dialog = true;
      this.billingData = payload;
    },
    memberShow(event) {
      this.$emit("memberShow", event);
    },
    clickExpense() {
      this.$emit("clickExpense");
    },
    clickLogout() {
      this.$emit("clickLogout");
    },
    getMemberBilling(memberId) {
      let memberBilling = this.billingList.filter(
        (billing) => billing.memberId === memberId
      );
      memberBilling.sort((a, b) => moment(b.reference) - moment(a.reference));
      return memberBilling[0];
    },
    getCardsInfo: function () {
      this.totalRevenue = 0;
      this.totalMembers = 0;
      let balanceData = [
        {
          value: 0,
          method: "Entrada",
          color: "#759A58",
        },
        {
          value: 0,
          method: "Saída",
          color: "#C04545",
        },
        {
          value: 0,
          method: "Total",
          color: "#759A58",
        },
      ];
      let revenue = {
        pix: 0,
        paper: 0,
        credit: 0,
        debit: 0,
      };
      let membersData = {
        paid: 0,
        inactive: 0,
        noPrice: 0,
        late: 0,
      };

      // revenue
      this.referenceBilling.forEach((billing) => {
        if (!isNaN(Number(billing.price))) {
          if (billing.type != "expense") {
            if (Object.prototype.hasOwnProperty.call(revenue, billing.method)) {
              revenue[billing.method] += Number(billing.price);
              this.totalRevenue += Number(billing.price);
            } else {
              revenue[billing.method] = Number(billing.price);
              this.totalRevenue = Number(billing.price);
            }
            balanceData[0].value += Number(billing.price);
            balanceData[2].value += Number(billing.price);
          } else {
            balanceData[1].value += Number(billing.price);
            balanceData[2].value -= Number(billing.price);
          }
        } else {
          membersData.noPrice += 1;
        }
      });

      let revenueKeys = Object.keys(revenue);
      let revenueData = [];
      revenueKeys.forEach((method) => {
        let methodTotal = {};

        methodTotal.method = method;

        let totalValue = (Math.round(revenue[method] * 100) / 100).toFixed(2);

        methodTotal.value = totalValue.toString();

        revenueData.push(methodTotal);
      });

      // members
      this.members.forEach((member) => {
        if (member.academies[this.currentAcademy.id].isActive === false) {
          membersData.inactive += 1;
        } else if (
          this.referenceBilling.filter(
            (billing) => billing.memberId === member.id
          ) != -1
        ) {
          membersData.paid += 1;
        } else {
          membersData.late += 1;
        }
      });
      // remove noPrice repetition
      /* membersData.paid -= membersData.noPrice; */
      // get total
      this.totalMembers =
        membersData.paid +
        membersData.inactive +
        membersData.late +
        membersData.noPrice;
      // atributtions

      let membersKeys = Object.keys(membersData);
      let membersDataFormatted = [];
      membersKeys.forEach((memberInfo) => {
        let membersFiltered = {};

        membersFiltered.method = memberInfo;
        membersFiltered.value = membersData[memberInfo].toString();

        membersDataFormatted.push(membersFiltered);
      });

      balanceData.forEach((balance) => {
        balance.value = (Math.round(balance.value * 100) / 100).toFixed(2);
      });

      this.cards["balance"].total =
        Number(balanceData[0].value) - Number(balanceData[1].value);
      this.cards["balance"].data = balanceData;

      this.cards["members"].total = this.totalMembers;
      this.cards["members"].data = membersDataFormatted;

      this.cards["revenue"].total = this.totalRevenue;
      this.cards["revenue"].data = revenueData;
    },
    changeMonth: function () {
      this.dialog = true;
    },
    getCardTotal: function (card) {
      card.total = 0;
      card.data.forEach((data) => {
        card.total = card.total + Number(data.value);
      });
    },
    changeReference(payload) {
      this.reference = payload.value;
      this.getCardsInfo();
    },
    formatValue(value) {
      let price = "--";
      if (value != undefined) {
        if (typeof value === "number") {
          price = value.toString();
        } else {
          price = value;
        }
        let indexComma = price.indexOf(".");
        if (indexComma != -1) {
          price =
            price.substring(0, indexComma) +
            "," +
            price.substring(indexComma + 1);
        } else {
          price = price + ",00";
        }
        indexComma = price.indexOf(",");
        let length = price.length;
        // how to pontuate correctly the number?
        if (length >= 7) {
          for (let i = indexComma - 1; i > 0; i--) {
            if ((length - i) % 3 === 0 && length - i > 0) {
              price = price.slice(0, i) + "." + price.slice(i);
            }
          }
        }
        price = "R$ " + price.slice(0, price.indexOf(",") + 3);
      }
      return price;
    },

    clickMenu(payload) {
      if (payload.action == "edit") {
        this.expenseEdit(payload);
      } else if (payload.action == "billingEdit") {
        this.billingEdit(payload);
      } else if (payload.action == "expenseEdit") {
        this.expenseEdit(payload);
      } else if (payload.action == "delete") {
        this.expenseDelete(payload);
      } else if (payload.action == "profile") {
        this.memberShow(payload.memberId);
      }
    },
    menuClick(payload) {
      switch (payload) {
        case "userProfile":
          this.$router.push("/account");
          break;
        case "payment":
          this.$router.push("/payment");
          break;
        case "settings":
          this.$router.push("/settings");
          break;
        case "logout":
          this.clickLogout();
          break;
        default:
          this.$router.push("/");
          break;
      }
    },
  },
  computed: {
    /* // TODO: change price to number in memberCreate and billingCreate*/
    /* // TODO: calculate totals based on members who paid this month*/
    referenceBilling() {
      return this.billingList.filter((billing) => {
        let date = new Date(billing.date);
        let reference = new Date(this.reference);
        if (
          date.getUTCMonth() === reference.getUTCMonth() &&
          date.getUTCFullYear() === reference.getUTCFullYear()
        ) {
          return billing;
        }
      });
    },
    formatedRevenue() {
      return this.formatValue(this.totalRevenue);
    },
    formatedReference() {
      return format.reference(this.reference);
    },
  },
};
</script>

<template>
  <section id="report-template-container" class="section-container">
    <the-toolbar :academy="currentAcademy" @clickIconRight="menuClick" />
    <span class="report-buttons">
      <app-input
        v-if="billingList.length > 0"
        inputType="button-date"
        @change="changeReference"
        dateType="month"
      />
      <app-button
        v-if="billingList.length > 0"
        name="add-expense"
        text="Adicionar Despesa"
        type="new-default"
        icon="mdi-currency-usd"
        width="calc(57%)"
        @click="clickExpense"
      />
    </span>
    <span
      v-show="billingList.length == 0"
      style="width: 100%; padding-bottom: 72px"
    >
      <modular-card
        :cardModules="noBillingCard"
        customStyle="min-height: calc((var(--vh, 1vh) * 100) - 192px) !important; justify-content: space-between;"
      >
        <app-button
          name="add-expense"
          text="Adicionar Despesa"
          icon="mdi-currency-usd"
          type="new-default"
          width="100%"
          @click="clickExpense"
        />
      </modular-card>
    </span>
    <span
      v-show="members.length > 0 && billingList.length > 0"
      class="report-container"
    >
      <report-card-list
        :cards="cards"
        :billingList="referenceBilling"
        :members="members"
        @clickMenu="clickMenu"
      />
      <!-- add different clicks for billing and expense Edits -->
    </span>
    <app-dialog
      :dialog="dialog"
      title="Confirmar Exclusão"
      text="Tem certeza que deseja excluir o Pagamento?"
      buttonCancel="Cancelar"
      buttonConfirm="Excluir"
      @clickConfirm="deleteConfirm"
      @clickCancel="deleteCancel"
    />
  </section>
</template>

<style lang="scss">
#report-template-container {
  gap: 16px;
}

.report-template {
  padding: 5vw;
}

.report-buttons {
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.report-container {
  min-width: 100% !important;
  width: 100% !important;
  padding: 0px 16px;
  height: calc((var(--vh) * 100) - 400) + "px";
  max-height: calc((var(--vh) * 100) - 400) + "px";

  h3 {
    font-size: 16px;
    text-align: center;
    margin: 0.5em auto;
    padding: 0.6em;
  }
  .report-reference {
    padding: 0.8em 0.6em;
    font-size: 18px;
  }
}

.spaced-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 12px !important;
}

.v-btn--fixed.v-btn--bottom {
  bottom: calc(56px + 16px);
}

.toolbar-container {
  padding: 40px 5vw;
}

.card-main {
  background-color: #ffffff !important;
  min-height: 16px;
  min-width: 100%;
  border-radius: 12px !important;
  margin-bottom: 16px;
  padding: 16px;
}
</style>
