var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"id":"toolbar","flat":""}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-avatar',{staticStyle:{"border-radius":"18px !important"},attrs:{"src":"http://jksbrasil.com.br/assets/images/logo_black.svg","color":"primary","tile":"","size":"48"}})],1),_c('v-spacer'),_c('v-toolbar-title',{staticStyle:{"text-align":"center"}},[_c('small',[_vm._v("Academia")]),_c('h5',[_vm._v(_vm._s(_vm.academy ? _vm.academy.name : ""))])]),_c('v-spacer'),_c('app-menu',{attrs:{"buttonType":"icon-only","icon":"mdi-dots-vertical","items":[
      /* {
        text: 'Minha Conta',
        value: 'userProfile',
      },
      {
        text: 'Pagamentos',
        value: 'payment',
      },
      {
        text: 'Configurações',
        value: 'settings',
      }, */
      {
        text: 'Sair',
        value: 'logout',
      } ]},on:{"click":_vm.clickIconRight}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }