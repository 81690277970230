<script>
import { mapGetters } from "vuex";
import HomeTemplate from "@/components/templates/Home/HomeTemplate.vue";
import AppLoading from "@/components/atoms/AppLoading.vue";
/* import debounceCall from "@/helpers/debounceCall";
 */
export default {
  name: "HomePage",
  data() {
    return {
      placeholder: true,
      activeTab: "members",
    };
  },
  // BILLING RELATED
  /* mounted() {
    if (this.currentUser != undefined) {
      if (Object.keys(this.currentUser).length > 0) {
        if (this.currentUser.academies === undefined) {
          this.$router.push("/academy/create");
        }
      } else if (this.currentAuth != undefined) {
        debounceCall(
          this.$store.dispatch("user/get", this.currentAuth.uid),
          1000
        );
      }
    } else if (this.currentAuth != undefined) {
      debounceCall(
        this.$store.dispatch("user/get", this.currentAuth.uid),
        1000
      );
    }
  }, */
  components: {
    HomeTemplate,
    AppLoading,
  },
  created() {
    if (this.currentUser.academies != undefined) {
      this.$store.dispatch("loading", false, { root: true });
      this.$store.dispatch("academy/get", this.currentAcademy.id, {
        root: true,
      });
    } else {
      this.$router.push("/academy/create");
    }
  },
  methods: {
    /*
      showUser missing
    */
    logout() {
      this.$store.dispatch("logout");
    },
    goToMemberShow(memberId) {
      this.$router.push("member/" + memberId);
    },
    goToBillingCreate(memberId) {
      this.$router.push("member/" + memberId + "/billing/create");
    },
    billingEdit(event) {
      this.$router.push(
        "member/" + event.memberId + "/billing/" + event.billingId + "/edit"
      );
    },
    billingDelete(payload) {
      // run confirmation modal
      this.$store.dispatch(
        "billing/delete",
        { academyId: this.currentAcademy.id, id: payload.billingId },
        { root: true }
      );
    },
    createAcademy() {
      this.$router.push("/academy/create");
    },
    goToMemberCreate() {
      this.$router.push("/member/create"); // go to member create
    },
    goToExpenseCreate() {
      this.$router.push(
        "academy/" + this.currentAcademy.id + "/billing/create"
      );
    },
    goToExpenseEdit(payload) {
      this.$router.push(
        "academy/" +
          this.currentAcademy.id +
          "/billing/" +
          payload.billingId +
          "/edit"
      );
    },
    // SWITCH FAB FUNCTION TO THIS AFTER ADD MEMBER NEW BUTTON
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    changeFilter(payload) {
      this.$store.dispatch("user/order", payload);
    },
    changeTab(payload) {
      this.$store.dispatch("changeTab", payload);
    },
  },
  computed: {
    ...mapGetters({
      hasAcademy: "academy/hasAcademy",
      currentAcademy: "academy/currentAcademy",
      currentUser: "user/currentUser",
      currentAuth: "auth/currentAuth",
      members: "user/members",
      billingList: "billing/billingList",
      hasBilling: "billing/hasBilling",
    }),
    currentTab() {
      return this.$store.state.currentTab;
    },
  },
};
</script>

<template>
  <span>
    <home-template
      v-if="currentUser != undefined"
      :currentAcademy="currentAcademy"
      :hasAcademy="hasAcademy"
      :members="members"
      :billingList="billingList"
      :hasBilling="hasBilling"
      :currentTab="currentTab"
      @clickExpense="goToExpenseCreate"
      @expenseEdit="goToExpenseEdit"
      @clickLogout="logout"
      @billingEdit="billingEdit"
      @billingDelete="billingDelete"
      @fabClick="goToMemberCreate"
      @changeFilter="changeFilter"
      @clickProfile="goToMemberShow"
      @clickBilling="goToBillingCreate"
      @changeTab="changeTab" />

    <app-loading v-else
  /></span>
</template>
