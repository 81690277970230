<script>
import format from "@/helpers/format";
import paymentMethods from "@/constants/payment-methods";
import AppIcon from "../atoms/AppIcon.vue";
import AppListItem from "../atoms/AppListItem.vue";
import AppMenu from "../atoms/AppMenu.vue";

export default {
  components: {
    AppIcon,
    AppMenu,
    AppListItem,
  },
  name: "ReportDetailItemBalance",
  props: {
    id: String,
    name: {
      type: String,
      default: "",
    },
    billing: {
      type: Object,
    },
  },
  computed: {
    formatedDate() {
      return format.date(this.billing.date);
    },
    formatedName() {
      let formatedName = format.name(this.name);
      if (formatedName.length > 25) {
        return formatedName.substring(0, 24) + "...";
      } else {
        return formatedName;
      }
    },
    formatedReference() {
      return format.reference(this.billing.reference);
    },
    billingPrice() {
      let price = "--";
      if (this.billing.price != undefined) {
        if (typeof this.billing.price === "number") {
          price = this.billing.price.toString();
        } else {
          price = this.billing.price;
        }
        let indexComma = price.indexOf(".");
        if (indexComma != -1) {
          price =
            price.substring(0, indexComma) +
            "," +
            price.substring(indexComma + 1);
        } else {
          price = price + ",00";
        }
        indexComma = price.indexOf(",");
        let length = price.length;
        if (length >= 7) {
          for (let i = indexComma - 1; i > 0; i--) {
            if ((length - i) % 3 === 0 && length - i > 0) {
              price = price.slice(0, i) + "." + price.slice(i);
            }
          }
        }
      }
      return price;
    },
    billingMethod() {
      let method = "--";
      if (this.billing.method.length > 0)
        paymentMethods.find((item) => {
          if (item.value === this.billing.method) {
            method = item.text;
          }
        });
      return method;
    },
  },
  methods: {
    clickMenu(payload) {
      let newPayload = {};
      newPayload.action = payload;
      newPayload.billingId = this.billing.id;
      newPayload.memberId = this.billing.memberId;
      this.$emit("clickMenu", newPayload);
    },
    textStyle(billingType, billingPrice) {
      if (billingPrice == 0) {
        return "text-align: right; color: #85B6FF";
      } else {
        if (billingType != "expense") {
          return "text-align: right; color: #759A58";
        } else {
          return "text-align: right; color: #C04545";
        }
      }
    },
  },
};
</script>

<template>
  <app-list-item class="report-detail-item-container">
    <span class="report-member-info">
      <div class="report-member-title">
        <p class="report-member-name">{{ formatedName }}</p>

        <p
          class="report-member-method"
          :style="
            billing.type != 'expense'
              ? 'text-align: right; color: #759A58'
              : 'text-align: right; color: #C04545'
          "
        >
          {{ billing.type != "expense" ? "Entrada" : "Saída" }}
        </p>
      </div>
      <div class="report-member-data-container">
        <div class="report-member-data">
          <p v-if="billing.id != undefined" class="report-member-data-date">
            <app-icon color="#797979" size="16" icon="mdi-calendar-today" />
            {{ formatedDate }}
          </p>
          <p v-else></p>

          <p
            class="report-member-data-price"
            :style="textStyle(billing.type, billing.price)"
          >
            <app-icon
              :style="textStyle(billing.type, billing.price)"
              size="16"
              icon="mdi-currency-usd"
            />
            {{ billingPrice }}
          </p>
        </div>
      </div>
    </span>
    <app-menu
      v-if="billing.id != undefined"
      buttonType="new-icon"
      customStyle="width: 32px ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid #94ACCF;"
      icon="mdi-dots-horizontal"
      :items="[
        {
          text: 'Editar',
          value: 'expenseEdit',
        },
        {
          text: 'Excluir',
          value: 'delete',
        },
      ]"
      @click="clickMenu"
    />
  </app-list-item>
</template>

<style lang="scss">
.report-detail-item-container {
  .v-list-item__content {
    min-height: auto !important;
    border-bottom: 1px solid rgba(148, 172, 207, 0.3);
    padding: 0px;
    display: grid;
    grid-template-columns: calc(100% - 40px) 32px;
    gap: 8px;
  }
}

p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: 12px;
  color: #797979;

  small {
    font-weight: 500;
    font-size: 12px;
    color: #797979;
  }
}

.report-member-name {
  text-align: left;
}

.report-member-data-container {
  width: 100%;
}

.report-member-info {
  width: 100%;
  padding: 4px 0px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.report-member-data-date {
  width: 35%;
  display: flex;
  gap: 2px;
}
.report-member-data {
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.report-member-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;

  .report-member-data-price {
    margin-left: auto;
    color: #759a58;
  }

  .report-member-data-method {
    text-align: right;
  }
}
</style>
