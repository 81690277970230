<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "AppChartDoughnut",
  components: {
    Doughnut,
  },
  props: {
    backgroundColor: Array,
    data: Array,
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    width: {
      type: Number,
      default: 180,
    },
    height: {
      type: Number,
      default: 180,
    },
  },
  created() {
    this.chartData.datasets[0].backgroundColor = this.backgroundColor;
    this.chartData.datasets[0].data = this.data;
  },
  beforeUpdate() {
    this.chartData.datasets[0].backgroundColor = this.backgroundColor;
    this.chartData.datasets[0].data = this.data;
  },
  data() {
    return {
      chartData: {
        /* labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"], */
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        animation: {
          duration: 0,
        },
        maintainAspectRatio: false,
        cutout: 60,

        legend: {
          display: false,
        },
        events: [],
        /* layout: {
          padding: 20,
        }, */
      },
    };
  },
};
</script>

<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :width="width"
    :height="height"
  />
</template>
