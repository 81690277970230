<script>
import AppCarousel from "@/components/atoms/AppCarousel.vue";
import ReportCard from "@/components/molecules/ReportCard.vue";

export default {
  name: "ReportCardList",
  components: { AppCarousel, ReportCard },
  data() {
    return {
      detailModifier: {
        balance: {
          filter: "all",
          order: "date",
        },
        revenue: {
          filter: "all",
          order: "date",
        },
        members: {
          filter: "all",
          order: "date",
        },
      },
      screenWidth: 0,
    };
  },
  props: {
    cards: Object,
    members: {
      type: Array,
      default: () => {
        [];
      },
    },
    billingList: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  created() {
    for (let card in this.cards) {
      this.cards[card].key = card;
    }
    this.screenWidth = window.innerWidth;
  },
  updated() {
    this.screenWidth = window.innerWidth;
  },
  methods: {
    changeOrder(payload) {
      this.detailModifier[payload.cardKey].order = payload.newOrder;
    },
    changeFilter(payload) {
      this.detailModifier[payload.cardKey].filter = payload.newOrder;
    },
    clickMenu(payload) {
      this.$emit("clickMenu", payload);
    },
  },
};
</script>

<template>
  <span style="width: 100%">
    <app-carousel v-if="screenWidth < 1200">
      <div class="carousel-item" v-for="card in cards" v-bind:key="card.key">
        <report-card
          :title="card.title"
          :dataList="card.data"
          :total="card.total"
          :cardKey="card.key"
          :chartType="card.chartType"
          :currentSort="detailModifier[card.key].order"
          :currentFilter="detailModifier[card.key].filter"
          :members="members"
          :billingList="billingList"
          @clickMenu="clickMenu"
          @changeFilter="changeFilter"
          @changeOrder="changeOrder"
        >
        </report-card>
      </div>
    </app-carousel>
    <span v-else style="width: 100%; display: flex; gap: 16px">
      <div class="carousel-item" v-for="card in cards" v-bind:key="card.key">
        <report-card
          :title="card.title"
          :dataList="card.data"
          :total="card.total"
          :cardKey="card.key"
          :chartType="card.chartType"
          :currentSort="detailModifier[card.key].order"
          :currentFilter="detailModifier[card.key].filter"
          :members="members"
          :billingList="billingList"
          @clickMenu="clickMenu"
          @changeFilter="changeFilter"
          @changeOrder="changeOrder"
        >
        </report-card>
      </div>
    </span>
  </span>
</template>

<style>
.carousel-item {
  width: 100% !important;

  @media screen and (min-width: 1200px) {
    max-width: 448px;
    width: 30%;
  }
}
</style>
