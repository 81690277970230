const userReportCategory = [
  {
    value: "paid",
    text: "Em dia",
    color: "#8AE046",
  },
  {
    value: "late",
    text: "Atrasados",
    color: "#F34871",
  },
  {
    value: "inactive",
    text: "Inativos",
    color: "#FFC165",
  },
  {
    value: "total",
    text: "Total",
    color: "#FFC165",
  },
  {
    value: "noPrice",
    text: "Pago Sem Valor",
    color: "#829EE6",
  },
];

export default userReportCategory;
