<script>
export default {
  name: "ReportCardSubtitleItem",
  data() {
    return {};
  },
  props: {
    title: String,
    value: String,
    color: String,
    cardKey: String,
  },
  computed: {
    finalColor() {
      if (this.title === "Total") {
        if (Number(this.value) > 0) {
          return "#759A58";
        } else if (Number(this.value) < 0) {
          return "#C04545";
        } else {
          return "#85B6FF";
        }
      } else {
        return this.color;
      }
    },
    formattedPrice() {
      let price = "--";
      if (this.value != undefined) {
        if (typeof this.value === "number") {
          price = Math.abs(this.value).toString();
        } else {
          price = Math.abs(Number(this.value)).toString();
        }
        let indexComma = price.indexOf(".");
        if (indexComma != -1) {
          price =
            price.substring(0, indexComma) +
            "," +
            price.substring(indexComma + 1);
        } else {
          price = price + ",00";
        }
        indexComma = price.indexOf(",");
        let length = price.length;
        if (length >= 7) {
          for (let i = indexComma - 1; i > 0; i--) {
            if ((length - i) % 3 === 0 && length - i > 0) {
              price = price.slice(0, i) + "." + price.slice(i);
            }
          }
        }
        price = "R$ " + price;
      }
      return price;
    },
  },
};
</script>

<template>
  <div class="report-subtitle-content">
    <span style="display: flex; flex-direction: column; gap: 4px">
      <div class="report-subtitle-item">
        <div
          class="report-subtitle-item-color"
          :style="'background-color: ' + finalColor"
        />
        <div class="report-subtitle-item-text">
          {{ title }}
        </div>
      </div>

      <div
        class="report-subtitle-value"
        :style="cardKey === 'balance' ? 'color: ' + finalColor : ''"
      >
        {{ cardKey === "members" ? value : formattedPrice }}
      </div>
    </span>
  </div>
</template>

<style lang="scss">
.report-subtitle-content {
  .report-subtitle-item {
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    line-height: 14px;

    .report-subtitle-item-color {
      border-radius: 50%;
      width: 0.5em;
      height: 0.5em;
    }
    .report-subtitle-item-text {
      color: #565656;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .report-subtitle-value {
    line-height: 14px;
    color: #909090;
    font-size: 12px;
    transform: translateY(-0.2em);
    font-weight: 500;
    margin-bottom: 0.3em;
  }
}
</style>
