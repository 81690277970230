<script>
import bottomMenu from "@/constants/bottom-menu";
import AppImage from "@/components/atoms/AppImage.vue";

export default {
  name: "TheSidebar",
  components: {
    AppImage,
  },
  data() {
    return {
      options: bottomMenu,
    };
  },
  props: {
    currentTab: String,
    width: Number,
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
    change(event) {
      this.$emit("change", event);
    },
  },
};
</script>

<template>
  <v-card
    class="sidebar-container"
    style="box-shadow: 0px 4px 34px rgb(33 76 158 / 25%) !important"
  >
    <v-navigation-drawer floating permanent :expand-on-hover="width < 1600">
      <v-list dense rounded>
        <v-list-item>
          <app-image
            image="logo-small"
            customStyle="height: 54px !important; min-height: 54px !important;margin-bottom: 56px;"
          />
        </v-list-item>

        <v-list-item
          v-for="option in options"
          :key="option.title"
          @click="change(option.value)"
          :class="option.value === currentTab ? 'sidebar-item-active' : ''"
          link
        >
          <v-list-item-icon style="margin: 16px 20px !important">
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ option.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item @click="emit(`change`)" link>
          <v-list-item-icon style="margin: 16px 20px !important">
            <v-icon style="transform: translateX(2px)">mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>

      <template v-slot:append>
        <div style="width: 48px; margin: 0 auto">
          <v-btn style="padding: 24px" icon>
            <v-icon> mdi-logout</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<style lang="scss">
.v-navigation-drawer {
  min-width: 80px !important;

  .v-list-item__avatar {
    width: 56px !important;
    margin: 0 auto !important;
    margin-bottom: 64px !important;
  }

  @media screen and (min-width: 1600px) {
    width: 256px !important;
  }
}

.sidebar-container {
  height: calc(100vh - 10vw);
  z-index: 3;
  border-radius: 20px;
  width: 80px;
  padding: 32px 0;

  @media screen and (min-width: 1600px) {
    width: 256px;
  }
}

.sidebar-item-active {
  .v-list-item__icon {
    .v-icon {
      color: #607ecc !important;
      text-shadow: 0px 0px 20px #0c8aff !important;
    }
  }
}
</style>
