<script>
import AppMenu from "@/components/atoms/AppMenu.vue";
export default {
  name: "TheToolbar",
  components: {
    AppMenu,
  },
  props: {
    academy: Object,
  },
  methods: {
    clickIconRight(payload) {
      this.$emit("clickIconRight", payload);
    },
  },
};
</script>

<template>
  <v-toolbar id="toolbar" flat>
    <v-btn icon>
      <v-avatar
        src="http://jksbrasil.com.br/assets/images/logo_black.svg"
        color="primary"
        tile
        style="border-radius: 18px !important"
        size="48"
      ></v-avatar>
    </v-btn>

    <v-spacer></v-spacer>

    <v-toolbar-title style="text-align: center">
      <small>Academia</small>
      <h5>{{ academy ? academy.name : "" }}</h5>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <app-menu
      buttonType="icon-only"
      icon="mdi-dots-vertical"
      @click="clickIconRight"
      :items="[
        /* {
          text: 'Minha Conta',
          value: 'userProfile',
        },
        {
          text: 'Pagamentos',
          value: 'payment',
        },
        {
          text: 'Configurações',
          value: 'settings',
        }, */
        {
          text: 'Sair',
          value: 'logout',
        },
      ]"
    />
  </v-toolbar>

  <!-- // TODO: Add here another toolbar option? Or 2 versions + flow of control?  -->
  <!-- desktop template -->
</template>

<style lang="scss">
#toolbar {
  height: 64px !important;
  max-height: 64px !important;
  border-radius: 20px 20px 20px 20px;

  // @media screen and (min-width: 1200px) {
  //   height: 80px !important;
  //   max-height: 80px !important;
  // }
  .v-toolbar__content {
    height: 64px !important;
    max-height: 64px !important;
    border-radius: 20px 20px 20px 20px;
    padding: 4px 8px;
    @media screen and (min-width: 1200px) {
      height: 80px !important;
      justify-content: flex-start;
    }
  }
}

.v-sheet.v-toolbar {
  padding: 0 0 0 12px;
  border: 0px;
  width: 100%;

  box-shadow: 0px 4px 34px rgb(33 76 158 / 25%) !important;
}
</style>
