var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card',{staticStyle:{"height":"calc((var(--vh) * 100) - 222px)"}},[_c('div',{staticClass:"report-card-container"},[_c('div',{staticClass:"report-card-graph"},[_c('div',{staticClass:"report-subtitle"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('report-card-subtitle',{staticStyle:{"padding-left":"4px"},attrs:{"dataList":_vm.dataList,"cardKey":_vm.cardKey}})],1),_c('div',{staticClass:"report-chart"},[_c('app-chart',{attrs:{"chartType":"bar","backgroundColor":_vm.backgroundColors,"data":_vm.percentages}})],1)]),_c('div',{staticClass:"report-card-filters"},[_c('app-menu',{attrs:{"title":"Ordenar","buttonType":"new-default","color":"#FFFFFF","icon":"mdi-swap-vertical","textColor":"#94ACCF","current":_vm.currentSort,"items":[
          {
            text: 'Nome',
            value: 'name',
          },
          {
            text: 'Valor',
            value: 'price',
          },
          {
            text: 'Data',
            value: 'date',
          } ],"customStyle":"width: calc(100% - 1px) ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid rgba(148, 172, 207, 0.4);"},on:{"click":_vm.changeOrder}}),_c('app-menu',{attrs:{"buttonType":"new-default","title":"Filtrar","color":"#FFFFFF","icon":"mdi-filter-variant","textColor":"#94ACCF","current":_vm.currentFilter,"items":[
          {
            text: 'Despesas',
            value: 'expense',
          },
          {
            text: 'Entradas',
            value: 'income',
          },
          {
            text: 'Todos',
            value: 'all',
          } ],"customStyle":"width: calc(100% - 1px) ;height: 26px !important; font-size: 12px; font-weight: 500; box-shadow: none !important; border: 1px solid rgba(148, 172, 207, 0.4);"},on:{"click":_vm.changeFilter}})],1),_c('div',{staticClass:"report-card-details",staticStyle:{"overflow-y":"scroll"}},[_c('report-detail-list',{attrs:{"members":_vm.members,"billingList":_vm.billingList,"type":_vm.cardKey,"currentSort":_vm.currentSort,"currentFilter":_vm.currentFilter},on:{"clickMenu":_vm.clickMenu}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }