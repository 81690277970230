<script>
import ReportDetailItem from "../molecules/ReportDetailItem.vue";
import AppList from "@/components/atoms/AppList.vue";
import moment from "moment";

export default {
  components: {
    ReportDetailItem,
    AppList,
  },
  name: "ReportDetailList",
  data() {
    return {};
  },
  props: {
    members: {
      type: Array,
      default: () => {
        [];
      },
    },
    type: String,
    billingList: {
      type: Array,
      default: () => {
        [];
      },
    },
    currentFilter: String,
    currentSort: String,
  },
  methods: {
    // Local Methods
    sortByName(list) {
      return list
        ? list.sort((a, b) => {
            let memberA = this.members.filter(
              (member) => member.id == a.memberId
            )[0];
            let memberB = this.members.filter(
              (member) => member.id == b.memberId
            )[0];
            return memberA.name.localeCompare(memberB.name);
          })
        : [];
    },
    sortByDescription(list) {
      return list
        ? list.sort((a, b) => {
            return a.description.localeCompare(b.description);
          })
        : [];
    },
    sortByDate(list) {
      return list ? list.sort((a, b) => moment(b.date).diff(a.date)) : [];
    },
    sortByPrice(list) {
      return list ? list.sort((a, b) => b.price - a.price) : [];
    },
    getMember(memberId) {
      let member = this.members.filter((member) => {
        if (member.id === memberId) {
          return member;
        }
      })[0];
      if (member != undefined) {
        return member;
      } else {
        return {
          id: "",
          name: "Nome não reconhecido",
        };
      }
    },
    clickMenu(payload) {
      this.$emit("clickMenu", payload);
    },
    applyFilter(list, parameter) {
      return list
        ? list.filter((a) => {
            if (parameter != "expense") {
              return a.type == parameter || a.type == undefined;
            } else {
              return a.type == parameter;
            }
          })
        : [];
    },
  },
  computed: {
    filteredBilling() {
      switch (this.currentFilter) {
        case "expense":
          return this.applyFilter(this.billingList, this.currentFilter);
        case "income":
          return this.applyFilter(this.billingList, this.currentFilter);
        case "all":
          return this.billingList;
        default:
          return this.billingList;
      }
    },
    orderedBilling() {
      switch (this.currentSort) {
        case "name":
          if (this.type == "balance") {
            return this.sortByDescription(this.filteredBilling);
          } else {
            return this.sortByName(this.filteredBilling);
          }
        case "date":
          return this.sortByDate(this.filteredBilling);
        case "price":
          return this.sortByPrice(this.filteredBilling);
        default:
          return this.filteredBilling;
      }
    },
  },
};
</script>

<template>
  <section
    v-if="members != undefined"
    id="report-container"
    class="section-container"
  >
    <app-list v-if="members.length > 0" id="report-list">
      <report-detail-item
        v-for="billing in orderedBilling"
        v-bind:key="billing.id"
        :id="getMember(billing.memberId).id"
        :type="type"
        :name="
          type === 'revenue'
            ? getMember(billing.memberId).name
            : billing.description
        "
        :billing="billing"
        @clickMenu="clickMenu"
      />
    </app-list>
  </section>
</template>

<style lang="scss">
section {
  overflow: visible;
}

#report-container {
  padding: 0 !important;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;

  .v-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column !important;
    align-items: center;
    gap: 4px 24px;

    @media screen and (min-width: 1200px) {
      flex-direction: column;
      :last-child {
        margin-right: 0;
      }
    }
    .v-list-item {
      width: 100%;
      min-height: auto;

      @media screen and (min-width: 768px) {
        flex: max(100%, 448px);
        max-width: max(100%, 448px);
      }
      @media screen and (min-width: 1200px) {
        width: max(100%, 448px);
        max-width: max(100%, 448px);
      }
    }
  }
}

#report-list {
  width: 100%;
  padding: 16px;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 12px !important;
}

.v-btn--fixed.v-btn--bottom {
  bottom: calc(56px + 16px);
}
.toolbar-container {
  padding: 40px 5vw;
}
</style>
