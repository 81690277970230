<script>
import ReportBalanceCard from "@/components/molecules/ReportBalanceCard.vue";
import ReportRevenueCard from "@/components/molecules/ReportRevenueCard.vue";
import ReportMemberCard from "@/components/molecules/ReportMemberCard.vue";

export default {
  components: { ReportBalanceCard, ReportRevenueCard, ReportMemberCard },
  name: "ReportCard",
  data() {
    return {
      localData: [],
    };
  },
  props: {
    title: String,
    dataList: Array,
    total: Number,
    cardKey: String,
    currentSort: String,
    currentFilter: String,
    members: Array,
    billingList: Array,
  },
  methods: {
    changeFilter(payload) {
      this.$emit("changeFilter", payload);
    },
    changeOrder(payload) {
      this.$emit("changeOrder", payload);
    },
    clickMenu(payload) {
      this.$emit("clickMenu", payload);
    },
  },
  computed: {
    revenueBilling() {
      return this.billingList.filter((billing) => billing.type != "expense");
    },
    balanceBilling() {
      let mensality = 0;
      let balanceList = [];
      balanceList = this.billingList.filter((billing) => {
        if (billing.type != "expense") {
          mensality += Number(billing.price);
        } else {
          return billing;
        }
      });
      mensality = (Math.round(mensality * 100) / 100).toFixed(2);
      balanceList.push({
        description: "Mensalidades",
        price: mensality,
      });
      return balanceList;
    },
  },
};
</script>

<template>
  <span
    style="margin: 0; padding: 0; width: 100%; height: 100%; max-width: 448px"
  >
    <report-balance-card
      v-if="cardKey === 'balance'"
      :title="title"
      :dataList="dataList"
      :total="total"
      :cardKey="cardKey"
      :currentSort="currentSort"
      :currentFilter="currentFilter"
      :billingList="balanceBilling"
      :members="members"
      @clickMenu="clickMenu"
      @changeFilter="changeFilter"
      @changeOrder="changeOrder"
    />
    <report-revenue-card
      v-if="cardKey === 'revenue'"
      :title="title"
      :dataList="dataList"
      :total="total"
      :cardKey="cardKey"
      :billingList="revenueBilling"
      :members="members"
      :currentSort="currentSort"
      :currentFilter="currentFilter"
      @clickMenu="clickMenu"
      @changeFilter="changeFilter"
      @changeOrder="changeOrder"
    />
    <report-member-card
      v-if="cardKey === 'members'"
      :title="title"
      :dataList="dataList"
      :total="total"
      :cardKey="cardKey"
    />
  </span>
</template>
